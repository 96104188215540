.user-list-container {
  width: 100%;
}

.user-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.user-card {
  width: 80%;
  max-width: 400px; /* Adjust maximum width as needed */
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-icon {
  margin-right: 20px;
}

.user-icon img {
  width: 50px;
  height: 50px;
}

.user-details {
  flex-grow: 1;
}

.user-name {
  margin: 0;
}

.user-link {
  text-decoration: none;
  color: #333;
}

.user-info {
  margin-bottom: 10px; /* Add margin below user info */
}

.connect-button {
  margin-left: auto; /* Move button to the right */
  background-color: #6e34bc;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.connect-button:hover {
  background-color: #0056b3;
}

.loading-text,
.no-users-text {
  margin-top: 20px;
  font-weight: bold;
}
