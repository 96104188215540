.__logo {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
.sidebar_profile {
  width: 80px;
  height: 80px;
}

.sidebar{
  width:23%;
  max-height: 640px;
}

.bar{
  height: 530px;
}
.__body{
  width:54%;
}
.post{
  max-width: 744px;
}

/* active navbar */
.active {
  
  color: #2e046b;
  border-bottom: 2px solid #2e046b;
  /* color3: #dbc6f883 */
  /* color : #535252; */
}



/* OptionBOX chatAI */

.option_box{
  width: 450px;
  height: 150px;
}

.posted_img{
  max-height: 450px;
}